<template>
  <v-menu
      max-width="25%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text>
        <color :color="value"></color>
      </v-btn>
    </template>

    <v-card class=" text-center" width="360">
      <v-card-text class=" d-flex align-center flex-row flex-wrap">
          <color @click.native="$emit('input', null)" title="Aucune" large></color>
          <color v-for="(color, i) in colors" :key="i" :color="color" :title="color"
                 @click.native="$emit('input', color)" large></color>
      </v-card-text>
    </v-card>

  </v-menu>
</template>

<script>
import colors from "vuetify/lib/util/colors"

export default {
  name: "ColorPicker",
  props: {
    value: String
  },
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color")
  },
  computed: {
    colors: function () {
      return [
        colors.shades.white,
        colors.grey.darken4,
        "primary",
        ...Object.keys(colors).filter(a=> a.toString() != "shades")
      ]
    }
  },
  methods: {
    update: function (color) {
      this.$emit("input", color)
    }
  }
}
</script>

<style scoped>
</style>